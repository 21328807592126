<template>
	<div class="bullets">
		<div
			v-for="(el, index) in new Array(5)"
			:key="index"
			class="bullet__hitbox"
			@click="() => $emit('goto', index + 1)"
		>
			<div
				class="bullet" 
				:class="{ 'active': step == index+1 }"
			>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "bullets",
	props: {
		step: {
			type: Number,
			required: true
		}
	}
}
</script>

<style scoped lang="sass">
.bullets
	display: flex

	.bullet
		$self: &
		background-color: $bullet
		height: 10px
		width: 10px
		border-radius: 50%
		cursor: pointer
		transition: background-color .4s ease
		&__hitbox
			padding: 6px

		&.active
			background-color: $bullet-active

.bullet__hitbox:hover
	.bullet:not(.active)
		background-color: $secondary-dark

</style>