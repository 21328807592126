<template>
	<footer class="tutorial__footer">
		<div class="container">
			<b-bullets :step="step" @goto="(newStep) => $emit('goto', newStep)"/>
			<b-button
				@click="() => $emit('goto', step + 1)"
				bordered
				class="next"
			>
				Далее
				<svg class="next__icon" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.48 0.139999L5.46 1.18L8.44 4.22H0.34V5.78H8.44L5.46 8.82L6.48 9.86L11.34 5L6.48 0.139999Z" fill="#6118FD"/>
				</svg>

			</b-button>
		</div>
	</footer>
</template>

<script>
import button from "@/components/basic/button.vue"
import bullets from "./bullets.vue"

export default {
	name: "control",
	components: {
		"b-button": button,
		"b-bullets": bullets
	},
	props: {
		step: {
			type: Number,
			required: true
		}
	}
}
</script>

<style scoped lang="sass">
.next
	&:hover
		path
			fill: $white
			

	&__icon
		margin-left: 3px

.container
	padding-left: 24px
	padding-right: 24px
	display: flex
	align-items: center
	justify-content: space-between

@media screen and (min-width: $xs)
	.container
		max-width: 467px
		margin: 0 auto

@media screen and (min-width: $sm)
	.container
		flex-direction: column
		grid-gap: 90px

		& div:nth-child(1)
			order: 2

		& div:nth-child(2)
			order: 1

@media screen and (min-width: $md)
	.container
		flex-direction: row

@media screen and (min-width: $xl)
	.container
		flex-direction: column
		grid-gap: 58px

		& div:nth-child(1)
			order: 2

		& div:nth-child(2)
			order: 1
</style>