<template>
	<button
		class="button"
		:class="{ 'large': large, 'block': block, 'error': error, 'bordered': bordered }"
		:disabled="disabled"
	>
		<slot/>
	</button>
</template>

<script>
export default {
	name: "b-button",
	props: {
		color: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		bordered: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped lang="sass">

.button
	font-size: 16px
	font-family: $main_font
	line-height: 20px
	font-weight: 500
	border-radius: 10px
	border: none
	padding: 8px 16px
	cursor: pointer
	color: $main-light
	background-color: $accent-buttons
	transition: background-color .2s ease, box-shadow .2s ease, color .2s ease
	&.bordered
		background-color: transparent
		border: 1px solid $bullet-active
		color: $bullet-active
		font-weight: 500
		&:hover
			background-color: $bullet-active
			color: $white
	&.large
		font-size: 20px
		line-height: 24px
		padding: 18px 25px
	&:disabled
		cursor: auto
		background-color: $buttons-disabled
	&.error
		background-color: $error
	&.error:disabled
		background-color: desaturate(lighten($error, 20%), 40%)
	&.block
		display: block
		width: 100%
	&:hover:not(:disabled):not(.bordered):not(.error)
		background: darken($accent-buttons, 8%)
		box-shadow: 0px 4px 8px rgba(85, 8, 190, 0.32)
	&.error:hover:not(:disabled)
		background: darken($error, 8%)
		


</style>