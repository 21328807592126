<template>
	<div class="tutorial">
		<template v-if="step < 6">
			<div class="container">
				<div class="tutorial__header">
					<div class="title title--subtitle">
						Привет! У нас
					</div>
					<div class="title">новый интерфейс</div>
				</div>
				<b-tutorial-content :step="step"></b-tutorial-content>	
				<b-tutorial-control :step="step" @goto="(newStep) => step = newStep"></b-tutorial-control>	
			</div>
		</template>
		<template v-else>
			<b-ending @goto="(newStep) => step = newStep"></b-ending>
		</template>
	</div>
</template>

<script>
import tutorialContent from "@/components/tutorial/content.vue";
import tutorialControl from "@/components/tutorial/control.vue";
import ending from "@/components/tutorial/ending.vue";
export default {
	name: 'tutorial-view',
	components: {
		'b-tutorial-content': tutorialContent,
		'b-tutorial-control': tutorialControl,
		'b-ending': ending
	},
	data: () => ({
		step: 1
	})
}
</script>

<style scoped lang='sass'>
.tutorial
	height: 100%

.container
	height: 100%
	padding: 24px
	display: flex
	flex-direction: column
	justify-content: space-between

.title
	font-weight: 600
	font-size: 28px
	line-height: 140%
	text-align: center

	&--subtitle
		font-weight: normal
		font-size: 20px
		ine-height: 130%
		color: $secondary-dark


</style>