<template>
	<div class="tutorial__text">
		<div class="container">
			<div class="tutorial__title">
				{{data[step-1]}}
			</div>

			<div class="tutorial__text">

				<template v-if="step == 1">
					Мы автоматически определяем платформу 
и показываем актуальные для нее сборки. Вы&nbsp;можете переключать платформы самостоятельно используя кнопку
				<b-selector min disabled style="vertical-align: -8px; transform: scale(0.8)"/>
				</template>
				<template v-if="step == 2">
					Вы можете 
					<span class="no-break">закрепить
						<svg-icon icon-class="pin-p" style="font-size: 1.3em"/>
					</span> проекты со страницы игры для быстрого доступа к ним. Закрепленные проеты поднимаются на самый вверх списка в левой панели
				</template>
				<template v-if="step == 3">
					Для быстрого доступа к актуальным веткам в карточке игры, вы можете <span class="no-break">
						использовать <svg-icon icon-class="heart" style="font-size: 18px;"/>
					</span>чтобы поднять нужную ветку наверх.
				</template>
				<template v-if="step == 4">
					Нажмите на <span class="no-break">кнопку<img class="text__icon text__icon--t3" src="/img/list_btn.svg" alt="heart"></span> и вы увидите последние изменения в интересующей вас сборке.
				</template>

				<template v-if="step == 5">
					Чтобы получить прямую ссылку на загрузку билда, воспользуйтесь встроенным генератором qr-кодов
					<span class="qr__wrapper"><svg-icon icon-class="qr" style="font-size: 14px;"/></span>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
import selector from '@/components/platformSelect'

export default {
	name: "tutorial-text",
	components: {
		'b-selector': selector
	},
	props: {
		step: {
			type: Number,
			required: true
		}
	},
	data: () => ({
		data: [
			'Автоопределение платформы',
			'Закрепление проектов',
			'Любимая ветка',
			'Логи выбраной сборки',
			'Генерация QR-кода',
		]
	})
}
</script>

<style scoped lang="sass">
.tutorial__title
	text-align: center
	font-weight: 600
	font-size: 18px
	line-height: 24px
	margin-bottom: 20px

.tutorial__text
	text-align: center
	height: 150px
	line-height: 140%

.container
	padding-left: 24px
	padding-right: 24px
	max-width: 440px
	margin: 0 auto

.text__icon
	display: inline-block
	transform: translateY(2px)
	&--t1
		transform: translateY(3px)
		margin: 0 4px
	&--t2
		transform: translateY(6px)
		margin: 0 1px
	&--t3
		transform: translateY(8px)
		margin: 0 5px


.no-break
	display: inline-block
	word-break: no-break


.qr__wrapper
	display: inline-flex
	justify-content: center
	align-items: center
	background: #DBDBDB
	width: 24px
	height: 24px
	border-radius: 6px
	vertical-align: -6px
	position: relative
	bottom: 3px
</style>