<template>
	<div class="tutorial__ending">
		<b-header force-center/>
		<main class="tutorial__main">
			<h2 class="tutorial__header">Отлично!</h2>
			<div class="images">
				<div class="images__container">
					<img
						class="image"
						src="/img/games/1.png"
						alt="game image #1"
					>
					<img 
						v-for="(_, index) in Array(4)"
						:key='index'
						class="image image--small"
						:src="`/img/games/${index+2}.png`"
						:alt="`game image #${index+2}`"
					>
				</div>
			</div>
			<div class="text">
				<div class="text__header">
					Теперь вы можете начать работу с&nbsp;хранилищем билдов!
				</div>
				<div class="text__sub">
					Если найдет баги и ошибки, воспользуйтесь формой&nbsp;обратной связи в футере
				</div>
			</div>
			<b-button class="text__button" @click="start">Поехали!</b-button>
		</main>
		<footer class="footer">
			<b-button class="footer__button" @click="start">Поехали!</b-button>
			<div class="retry">
				<img class="retry__icon" src="/img/back_button.svg" alt="retry">
				<span class="retry__text" @click="() => $emit('goto', 1)">Повторить</span>
			</div>
		</footer>
	</div>
</template>

<script>
import header from '@/components/header.vue'
import button from '@/components/basic/button.vue'

export default {
	name: "tutor-ending",
	components: {
		'b-header': header,
		'b-button': button
	},
	methods: {
		start(){
			this.$router.push({name: "Root"});
		}
	}
}
</script>

<style scoped lang="sass">
.tutorial
	&__ending
		display: flex
		flex-direction: column
		height: 100%
		justify-items: space-between
		align-items: center

	&__header
		text-align: center
		color: $main-dark
		font-weight: 600
		font-size: 28px
		line-height: 36px
		margin: 0
		margin-bottom: 13px
		margin-top: 12px
		padding: 0


.images
	display: flex
	justify-content: center
	align-items: center
	&__container
		position: relative
		display: flex
		justify-content: center
		align-items: center

.image

	&--small
		position: absolute

		&:nth-child(2)
			top: 3px
			left: -18px
			transform: translateX(-100%)

		&:nth-child(3)
			bottom: 40px
			left: -17px
			transform: translateX(-100%)

		&:nth-child(4)
			top: 5px
			right: -8px
			transform: translateX(100%)

		&:nth-child(5)
			bottom: 40px
			right: -15px
			transform: translateX(100%)

.text
	text-align: center
	padding: 0 24px


	&__header
		font-size: 16px
		line-height: 20px
		margin-bottom: 24px
		color: $main-dark

	&__sub
		font-size: 12px
		line-height: 16px
		color: $secondary-dark
		margin-bottom: 70px

	&__button
		display: none

.footer__button, .text__button
		padding: 15px 75px
		background: $bullet-active
		margin: 0 auto
		// display: block
		font-size: 20px

.retry
	display: flex
	justify-content: center
	align-items: center
	cursor: pointer
	padding: 13px
	&__text
		text-decoration: underline
		color: $secondary-dark

	&__icon
		margin-right: 8px
		display: none

@media screen and (min-width: $xs)
	.tutorial
		&__main
			flex: 1 0
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
	.text
		padding: 0
		max-width: 388px
		margin: 0 auto
		&__header
			font-size: 18px
			line-height: 26px
			max-width: 360px
			margin: 0 auto 24px

		&__sub
			font-size: 14px
			line-height: 18px
			margin-bottom: 32px

		&__button
			display: block

	.footer
		padding: 24px
		&__button
			display: none

	.retry
		&__icon
			display: inline-block

@media screen and (min-width: $md)
	.text
		&__button
			display: none

	.footer
		width: 100%
		display: grid
		grid-template-columns: 10% auto 10%
		&__button
			display: block
			order: 1

	.retry
		order: 0

</style>