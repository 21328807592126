<template>
	<div class="tutorial__image-container">
		<div class="image__wrapper" :class="{ 'small': smallImage }">
			<picture>
				<source :srcset="`/img/tutorial/${step}.webp`" type="image/webp">
				<img class="tutorial__image" :src="`/img/tutorial/${step}.png`" alt="step image">
			</picture>
			<img class="hand" :src="`/img/hand.svg`" alt="hand" :style="handStyle">
		</div>
	</div>
</template>

<script>
export default {
	name: "tutorial-image",
	props: {
		step: {
			type: Number,
			required: true
		}
	},
	methods: {
		scale(num){
			return num * this.smallSize / this.bigSize;
		}
	},
	created(){
		for(let i = 0; i < 5; i++){
			const img = new Image();
			img.src = `/img/tutorial/${i+1}.webp`
		}
	},
	computed: {
		handStyle(){

			return { 'transform': `translate(${this.handX}px, ${this.handY}px)` }
		},
		handpos(){
			return this.handPosition[this.step - 1];
		},
		handX(){
			const base = this.handpos?.x ?? 0
			if(this.smallImage){
				return this.scale(base) + 18
			} else {
				return base
			}
		},
		handY(){
			const base = this.handpos?.y ?? 0
			if(this.smallImage){
				return this.scale(base)
			} else {
				return base
			}
		},
		smallImage(){
			const w = this.$windowWidth;
			return w <= 768 || (w >= 1024 && w <= 1440)
		}
	},
	data: () => ({
		smallSize: 190,
		bigSize: 260,
		handPosition: [
			{
				x: 32,
				y: 51
			},
			{
				x: 41,
				y: 90
			},
			{
				x: 19,
				y: 108
			},
			{
				x: 18,
				y: 106
			},
			{
				x: 19,
				y: 128
			},
		]
	})
}
</script>

<style scoped lang="sass">
.tutorial__image
	$size: 190px
	width: $size
	height: $size
	&-container
		display: flex
		justify-content: center
		align-items: center
		margin-bottom: 37px

		.image__wrapper
			position: relative
			.hand
				position: absolute
				right: 0
				top: 0
				transition: transform .2s .15s ease

@media screen and (min-width: $sm) and (max-width: $md)
	.tutorial__image
		$size: 260px
		width: $size
		height: $size

@media screen and (min-width: $xl)
	.tutorial__image
		$size: 260px
		width: $size
		height: $size

</style>