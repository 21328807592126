<template>
	<div class="tutorial__content">
		<b-image :step="step"></b-image>
		<b-text :step="step"></b-text>
	</div>
</template>

<script>
import image from "./image.vue"
import text from "./text.vue"

export default {
	name: "tutorial-content",
	components: {
		"b-image": image,
		"b-text": text
	},
	props: {
		step: {
			type: Number,
			required: true
		}
	}
}
</script>

<style>

</style>